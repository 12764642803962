/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
  header: false as any,
  sidebar: {
    type: 'menu' as 'menu',
  },
  list: '',
  prop: '',
}
