import React from "react";
// import { logoutRequest } from "../../../resources/auth/reducer/actions";
// import { useDispatch, useSelector } from "react-redux";
// import { AppState } from "../../../store/reducers";
import LoaderTextComponent from "../atoms/LoaderTextComponent";

const HeaderComponent = (props: any) => {
  // const dispatch = useDispatch();
  // const user = useSelector((state: AppState) => state.user);
  const user = {loading: false, user: { name: 'Concetto', avatar: 'https://source.unsplash.com/QAB-WJcbgJk/60x60' }};

  return (
    <nav className="navbar navbar-expand navbar-light bg-primary topbar static-top shadow">
      {/*  Sidebar Toggle (Topbar) --> */}
      <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
        <i className="fa fa-bars"></i>
      </button>

      {/*  Topbar Navbar --> */}
      <ul className="navbar-nav ml-auto">

        {/*  Nav Item - Search Dropdown (Visible Only XS) --> */}
        {/* <li className="nav-item dropdown no-arrow d-sm-none">
          <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fas fa-search fa-fw"></i>
          </a>
          <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
            <form className="form-inline mr-auto w-100 navbar-search">
              <div className="input-group">
                <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
                <div className="input-group-append">
                  <button className="btn btn-primary" type="button">
                    <i className="fas fa-search fa-sm"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </li> */}

        {/* <div className="topbar-divider d-none d-sm-block"></div> */}

        {/*  Nav Item - User Information --> */}
        {/* <li className="nav-item dropdown no-arrow">
          {user.loading ? <LoaderTextComponent /> :
            <div className="nav-link dropdown-toggle" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="mr-2 d-none d-lg-inline text-white small">
                {user.user.name}
              </span>
              <img className="img-profile rounded-circle" alt={user.user.name} src={user.user.avatar} />
            </div>
          }
          <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
            <a className="dropdown-item" href="#">
              <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
              Profile
            </a>
            <a className="dropdown-item" href="#">
              <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
              Settings
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              Logout
            </a>
          </div>
        </li> */}

      </ul>

      {/*  Logout Modal--> */}
      <div className="modal fade" id="logoutModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
              <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
            <div className="modal-footer">
              <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
              <button
                className="btn btn-primary"
                onClick={(): void => {
                  // dispatch(logoutRequest());
                }}>
                  Logout
                </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default HeaderComponent;
