import { INITIAL_STATE } from './initialState';
import { createReducer } from 'reduxsauce';
import { GeneralTypes } from './actions';

interface State {
  sidebar: {
    type: 'menu' | 'editor'
  };
  header: {

  };
  list: string;
  prop: string;
};

export const sidebarSetContent = (state: State, {data: {type}}: any): State => {
  return {
    ...state,
    sidebar: {
      type,
    },
  };
}

export const setListUrl = (state: State, {data}: any): State => {
  return {
    ...state,
    list: data,
  };
}

export const setPropUrl = (state: State, {data}: any): State => {
  return {
    ...state,
    prop: data,
  };
}

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [GeneralTypes.SIDEBAR_SET_CONTENT]: sidebarSetContent,
  [GeneralTypes.SET_LIST_URL]: setListUrl,
  [GeneralTypes.SET_PROP_URL]: setPropUrl,
})
