import React from 'react';

const Login = (props: any) => {
  return (
    <>
      Login page
    </>
  );
};

export default Login;
