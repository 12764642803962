import { put, call, select } from 'redux-saga/effects'
import Actions from '../stores/workflows/actions';
import Api from '../api/api'

export function* loadWorkflow({wid}: any) {
  try {
    const response = yield call(Api.getWorkflowDetails, wid);
    yield put(Actions.loadWorkflowSuccess(response));
  } catch (e) {
    yield put(Actions.loadWorkflowFailure(e));
  }
}

const getWorkflow = (state: any) => state.workflows.data[state.workflows.selectedWorkflowId];

export function* updateWorkflowBlock() {
  try {
    const workflow = yield select(getWorkflow);
    yield call(Api.updateWorkflowBlock, workflow);
    yield put(Actions.updateWorkflowSuccess());
  } catch (e) {
    yield put(Actions.updateWorkflowFailure(e));
  }
}
