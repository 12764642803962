const strings: any = {
  'it': {
    'home.title': 'Tutti i workflow',
    'sidebar.block-id': 'id blocco',
    'sidebar.workflow-id': 'id workflow',
    'sidebar.is-active': 'Attivo',
    'sidebar.exec': 'exec',
    'sidebar.select-process-call': 'Scegli una process call',
    'sidebar.select-workflow': 'Seleziona un wf',
    'sidebar.select-default-lang': 'Seleziona linguaggio default',
    'sidebar.description': 'descrizione',
    'sidebar.elements': 'elementi',
    'sidebar.label': 'label',
    'sidebar.type': 'tipo',
    'sidebar.var_name': 'nome variabile',
    'sidebar.uri': 'uri',
    'sidebar.codes': 'Codici',
    'sidebar.actions': 'Canc',
    'sidebar.newrow': 'Aggiungi nuova riga',
    'general.subtype': 'Sub-tipo',
    'general.process-call': 'Process Call',
    'general.css': 'class',
    'general.exits': 'Uscite',
    'general.exit': 'Uscita',
    'general.no-results': 'Nessun risultato',
    'general.delete': 'elimina',
    'general.settings': 'impostazioni',
    'general.edit': 'modifica',
    'general.noname': 'Nessun nome',
    'general.save': 'Salva',
    'general.cancel': 'annulla',
    'general.add-exit': 'Aggiungi exit',
    'general.sure-delete': 'Sei sicuro di voler eliminare?',
    'general.mandatory': 'obbligatorio',
    'general.note': 'NOTE',
    'editor.add-block': 'Aggiungi blocco',
    'editor.add-block-st': 'Standard',
    'editor.add-block-rt': 'Return',
    'editor.add-block-nt': 'Note',
    'editor.add-block-sw': 'Workflow call',
    'editor.delete-block': 'Elimina blocco',
    'editor.duplicates-block': 'Duplica blocco',
    'editor.settings': 'Impostazioni workflow',
    'editor.add-new-element': 'Nuovo elemento',
    'settings.name': 'Nome',
    'settings.description': 'Descrizione',
    'settings.valid_from': 'Valido da',
    'settings.valid_until': 'Valido fino',
    'settings.exec': 'Exec',
    'settings.supp_langs': 'Lingue supportate',
    'settings.lang': 'Lingua di default',
    'settings.workflow-id': 'Id Workflow',
  },
  'en': {
    'home.title': 'All workflows',
    'sidebar.block-id': 'block id',
    'sidebar.workflow-id': 'workflow id',
    'sidebar.is-active': 'is active',
    'sidebar.exec': 'exec',
    'sidebar.select-process-call': 'Select a process call',
    'sidebar.select-workflow': 'Select a wf',
    'sidebar.select-default-lang': 'select default lang',
    'sidebar.description': 'description',
    'sidebar.elements': 'elements',
    'sidebar.label': 'label',
    'sidebar.type': 'type',
    'sidebar.var_name': 'var name',
    'sidebar.uri': 'uri',
    'sidebar.codes': 'Codes',
    'sidebar.actions': 'Del',
    'sidebar.newrow': 'Add new row',
    'general.subtype': 'Subtype',
    'general.process-call': 'Process Call',
    'general.css': 'class',
    'general.exits': 'exits',
    'general.exit': 'exit',
    'general.no-results': 'No results',
    'general.delete': 'delete',
    'general.settings': 'settings',
    'general.edit': 'edit',
    'general.noname': 'No name',
    'general.save': 'Save',
    'general.cancel': 'cancel',
    'general.add-exit': 'Add new exit',
    'general.sure-delete': 'Are you sure you want to delete?',
    'general.mandatory': 'mandatory',
    'general.note': 'NOTE',
    'editor.add-block': 'Add block',
    'editor.add-block-st': 'Standard',
    'editor.add-block-rt': 'Return',
    'editor.add-block-nt': 'Note',
    'editor.add-block-sw': 'Workflow call',
    'editor.delete-block': 'Delete block',
    'editor.duplicates-block': 'Duplicates block',
    'editor.settings': 'Workflow settings',
    'editor.add-new-element': 'Add new element',
    'settings.name': 'Name',
    'settings.description': 'Description',
    'settings.valid_from': 'Valid from',
    'settings.valid_until': 'Valid until',
    'settings.exec': 'Exec',
    'settings.supp_langs': 'Supported languages',
    'settings.lang': 'Default lang',
    'settings.workflow-id': 'Workflow id',
  },
};

type Languages = keyof typeof strings;

const fallbackLang: Languages = 'en';
let lang: Languages = 'it';

const setLang = (language: 'it' | 'en') => {
  lang = language;
}

const getLang = () => {
  return lang;
}

const translate = (key: string) => {
  return strings[lang][key] || strings[fallbackLang][key] || key;
}

const i18n = {
  translate,
  setLang,
  getLang,
}

export default i18n;
