import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  loadWorkflowRequest: ['wid'],
  loadWorkflowSuccess: ['data'],
  loadWorkflowFailure: ['errors'],

  selectBlock: ['id'],

  createBlock: ['x_pos', 'y_pos', 'block_id', 'block_type'],

  moveBlock: ['data'],
  addConnection: ['data'],
  removeConnection: ['data'],

  updateLocalWorkflow: ['data'],
  duplicatesBlock: ['data'],
  deleteLocalWorkflowBlock: null,

  updateWorkflowRequest: null,
  updateWorkflowSuccess: null,
  updateWorkflowFailure: ['errors'],
})

export const WorkflowTypes = Types
export default Creators
