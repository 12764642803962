import React from "react";

import "startbootstrap-sb-admin-2/vendor/bootstrap/js/bootstrap.bundle.min.js";
import '../../scss/index.scss';

import HeaderComponent from "../organisms/HeaderComponent";
import SidebarComponent from "../organisms/SidebarComponent";

const AppTemplate = ({children, ...props}: any) => (
  <>
    <div id="wrapper">
      <SidebarComponent />

      <div id="content-wrapper" className="d-flex flex-column w-auto" style={{flex: 1}}>
        <HeaderComponent />
        {children}
      </div>
    </div>
  </>
);

export default AppTemplate;
