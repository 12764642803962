import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Switch, Route as ReactRoute, RouteProps, useHistory } from 'react-router-dom';

import AppTemplate from './theme/components/templates/AppTemplate';

import Home from './theme/views/Home';
import Login from './theme/views/Login';
import NotFound from './theme/views/NotFound';
import WorkflowEdit from './theme/views/WorkflowEdit';
import WorkflowSettings from './theme/views/WorkflowSettings';

import { Provider, useDispatch, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react'
import createStore from './core/stores';
import Api from './core/api/api';
import useQuery from './core/hooks/query';
import GeneralActions from './core/stores/general/actions';
import { Workflow } from './core/api/interfaces';

const { store, persistor } = createStore()

const useParamsGuard = () => {
  const [isReady, setReady] = useState(false);

  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();

  const { data, selectedWorkflowId }= useSelector((state: any) => state.workflows);
  const workflow = useMemo(() => {
    return data[selectedWorkflowId] as Workflow
  }, [data, selectedWorkflowId]);

  useEffect(() => {
    const conn = query.get('conn');
    const list = query.get('list');
    const prop = query.get('prop');

    if (!(conn && list && prop)) {
      history.push('/404');
      return;
    }

    Api.setEndpoint(conn);
    if (workflow) {
      Api.setAuk(workflow.wid.split('|')[1].split(']')[0]);
    }

    dispatch(GeneralActions.setListUrl(list))
    dispatch(GeneralActions.setPropUrl(prop))

    setReady(true);
  }, [workflow])

  return [isReady];
}

const Route = ({children, ...props}: RouteProps) => {
  const [isReady] = useParamsGuard();

  return (
    <ReactRoute {...props}>
      {isReady && children}
    </ReactRoute>
  )
}

const Main = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={'/login'}>
          <Login />
        </Route>

        <AppTemplate>
          <Switch>
            {/* <Route exact path={'/'}>
              <Redirect to="/home" />
            </Route> */}
            {/* <Route exact path={'/home'}>
              <Home />
            </Route> */}
            <Route exact path={'/home/:workflow_id/edit'}>
              <WorkflowEdit />
            </Route>
            {/* <Route exact path={'/home/:workflow_id/settings'}>
              <WorkflowSettings />
            </Route> */}
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </AppTemplate>

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

const App = () => {
  return (
    /**
     * @see https://github.com/reduxjs/react-redux/blob/master/docs/api.md#provider-store
     */
    <Provider store={store}>
      {/**
       * PersistGate delays the rendering of the app's UI until the persisted state has been retrieved
       * and saved to redux.
       * The `loading` prop can be `null` or any react instance to show during loading (e.g. a splash screen),
       * for example `loading={<SplashScreen />}`.
       * @see https://github.com/rt2zz/redux-persist/blob/master/docs/PersistGate.md
       */}
      <PersistGate loading={null} persistor={persistor}>
        <Main />
      </PersistGate>
    </Provider>
  );
}

export default App;
