export enum c_type {
  'MESSAGE' = '0',
  'INPUT' = '1',
  'BUTTON' = '2',
  'MEDIA' = '3',
  'LINK' = '4',
  'TXT_ASSIGN' = '5',
  'SELECT' = '6',
  'FILE' = '7',
}

export interface Lang {
  label: string;
  uri: string;
}

export interface Langs {
  [key: string]: Lang;
}

export interface Element {
  elem_id: string;
  var_name: string;
  e_order: string;
  note: string;
  d_type: string;
  c_type: c_type | string; // hack: string for compatibility with response
  langs: Langs;
  exit_num: string;
  css: {
    class: string,
    display_info: {
      mandatory?: boolean, // per input, file, select
      subtype?:
        'default' // per tutti
        | 'read-code' | 'take-photo' | 'file-image' | 'file-document' // per file
        | 'exclusive' | 'multiple'
        | 'button' | 'iframe' // per link
        | 'textarea' | 'e-mail' | 'date' | 'gps-position' // per input
        | 'background' | 'align-Rx' | 'align-Lx' // multimedia
      ,
    }
  };
}

export interface Exit {
  exit_dir: string;
}

export type BlockType = null | '' | 'RT' | 'SW' | 'NT';

export interface Block {
  block_id: string;
  exec: string | null;
  description: string;
  type?: BlockType;
  x_pos: string;
  y_pos: string;
  last_mod: string;
  elements: Element[];
  exits: Exit[];
}

export interface Workflow {
  wid: string;
  name: string;
  is_active: string;
  description: string;
  supp_langs: string;
  valid_from: string;
  valid_until: string;
  last_mod: string;
  start_block_id: string;
  lang: string;
  blocks: Block[];
}

export interface Response {
  workflow: Workflow[];
}
